import { render, staticRenderFns } from "./PatientKontakt.vue?vue&type=template&id=78dbad56"
import script from "./PatientKontakt.vue?vue&type=script&lang=js"
export * from "./PatientKontakt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayKontakt: require('/builds/logo-yes/portal2022/frontend/components/global/display/Kontakt.vue').default})


import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'freier-slot-formular',
        event: ['create::freier-slot', 'edit::freier-slot'],
        titel: null,
        modelname: 'Freier Slot',
        model: 'termine'
      },
      emptyModel: {
        von: this.$moment().minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm'),
        bis: this.$moment().minutes(0).seconds(0).add(45, 'minutes').format('YYYY-MM-DDTHH:mm'),
        dauer: 45,
        mitarbeiter: [],
        termintyp: 8,
        ort_auswahl: null,
        ort: null,
        standort_id: null,
        standort: null,
        raum_id: null,
        raum: null,
        infos: '',
        serie: false,
        serie_anzahl: 10,
        serie_rhythmus: 1,
        fachbereich: null,
      }
      // Termintypen: 8: Einmalig Frei, 9: Dauerhaft frei
    }
  },
  computed: {
    bearbeiten() {
      if (this.model && this.model.created_at) return true;
      return false;
    }
  },
  watch: {},
  methods: {
    fillModel(model = null) {
      if (!model) model = {mitarbeiter: [this.$model]};
      if (model.hausbesuch == 1) model.ort_auswahl = 'Anderer Ort';
      else if (model.hausbesuch == 2) model.ort_auswahl = 'Anderer Ort';
      else if (model.ort) model.ort_auswahl = 'Anderer Ort';
      else if (model.raum_id || model.standort_id) {
        model.ort_auswahl = 'Praxis';
      }
      this.model = this.$fillMissing(model, this.$copy(this.emptyModel));
      this.updateTherapeut([this.$model]);
    },
    speichern() {
      if (!this.model.mitarbeiter.length || !this.model.von || !this.model.bis ||  this.model.von >= this.model.bis) return false;
      let send = this.$copy(this.model);
      if (send.serie && send.seriennachfolger) {
        send.seriennachfolger = send.seriennachfolger.map(el => el.id);
      }
      else delete send.seriennachfolger;
      if (send.ort_auswahl != 'Praxis') send.raum_id = null;
      else {
        send.ort = null;
        if (send.standort) send.standort_id = send.standort.id;
        if (send.raum) send.raum_id = send.raum.id;
      }
      this.$$store('termine', send, 'Termin gespeichert').then(response => {
        this.$nuxt.$emit('store-termin', response);
        let neuesDatum = this.$moment(response[0].von).format('YYYY-MM-DD');
        this.$nuxt.$emit('refresh-termine-'+neuesDatum);
        if (this.terminAlt) {
          let altesDatum = this.$moment(this.terminAlt.von).format('YYYY-MM-DD');
          if (altesDatum != neuesDatum) this.$nuxt.$emit('refresh-termine-'+altesDatum);
        }
        this.schliessen();
      });
    },
    updateTermintyp() {
      if (this.model.termintyp == 8) this.model.serie = false;
      else if (this.model.termintyp == 9) this.model.serie = true;
    },
    updateTherapeut(event) {
      if (event && event[0]) {
        let therapeut = event[0].ist_therapeut;
        if (therapeut !== null && therapeut !== false) this.model.fachbereich = therapeut;
      }
    },
  },
  mounted() {}

}

import { render, staticRenderFns } from "./VerordnungDokumentation.vue?vue&type=template&id=d8668dde"
import script from "./VerordnungDokumentation.vue?vue&type=script&lang=js"
export * from "./VerordnungDokumentation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayDebugId: require('/builds/logo-yes/portal2022/frontend/components/global/display/DebugId.vue').default,ModalsDetailsDokumentation: require('/builds/logo-yes/portal2022/frontend/components/modals/details/Dokumentation.vue').default,InternVerordnungenTherapiestundenDetailbuttons: require('/builds/logo-yes/portal2022/frontend/components/intern/verordnungen/therapiestunden/detailbuttons.vue').default})

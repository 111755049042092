
export default {
  mixins: [],
  props: ['kontakt'],
  data() {
    return {}
  },
  computed: {
    offeneAufgaben() {
      if (this.$$response('faelle')) return this.$$response('faelle').total;
      return null;
    }
  },
  watch: {},
  methods: {
    faelleAbrufen() {
      if (this.kontakt.model_type != 'App\\Models\\Patient') return;
      let filter = [];
      filter.push({where: ['betreffender_id', this.kontakt.model_id, 'betreffender_type', this.kontakt.model_type]});
      filter.push({offenOderBeiMir: []});
      this.$$fill('faelle', {filter: filter});
      this.$$abrufen('faelle');
    },
  },
  mounted() {
    this.faelleAbrufen();
  },

}

import { render, staticRenderFns } from "./Falluebersicht.vue?vue&type=template&id=57d3654a"
import script from "./Falluebersicht.vue?vue&type=script&lang=js"
export * from "./Falluebersicht.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternTelefonieFallBucket: require('/builds/logo-yes/portal2022/frontend/components/intern/telefonie/FallBucket.vue').default,InternTelefonieAnrufUebersicht: require('/builds/logo-yes/portal2022/frontend/components/intern/telefonie/AnrufUebersicht.vue').default})
